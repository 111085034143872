<template>
  <b-overlay :show="loading">
    <header class="mb-2 d-flex justify-content-start justify-content-md-end">
      <button class="btn btn-outline-primary" @click.prevent="openModal">
        Tambah
      </button>
    </header>
    <b-card>
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        :fields="fields"
        :items="materis"
      >
        <template #cell(index)="{ index }">
          {{ ++index }}
        </template>
        <template #cell(action)="{ item }">
          <section class="d-flex justify-content-center align-items-center">
            <button class="btn btn-sm btn-outline-primary mr-1">
              <feather-icon
                icon="EyeIcon"
                @click.prevent="viewDetail(item)"
              ></feather-icon>
            </button>
            <button class="btn btn-sm btn-outline-primary mr-1">
              <feather-icon
                icon="EditIcon"
                @click.prevent="edit(item)"
              ></feather-icon>
            </button>
            <button class="btn btn-sm btn-outline-danger">
              <feather-icon
                icon="TrashIcon"
                @click.prevent="hapus(item)"
              ></feather-icon>
            </button>
          </section>
        </template>
      </b-table>
    </b-card>

    <!-- Modal detail -->
    <b-modal
      id="modal-detail"
      size="md"
      no-close-on-backdrop
      no-close-on-esc
      title="Detail Materi"
    >
      <section>
        <h3 class="mb-2">
          <strong>{{ form.nama_materi }}</strong>
        </h3>
        <p>{{ form.deskripsi }}</p>
        <section v-if="form.uploaded_file" class="my-2">
          <strong>
            Lampiran terupload:
            <a target="_blank" :href="form.uploaded_file">{{
              getFileName(form.uploaded_file)
            }}</a>
          </strong>
        </section>
      </section>

      <template #modal-footer>
        <button
          class="btn btn-secondary"
          @click.prevent="$bvModal.hide('modal-detail')"
        >
          Tutup
        </button>
      </template>
    </b-modal>
    <!-- / -->

    <!-- Modal create and edit -->
    <b-modal
      id="modal-materi"
      no-close-on-backdrop
      no-close-on-esc
      size="md"
      title="Buat / Edit Materi"
    >
      <form action="">
        <b-form-group label="Judul Materi" class="mb-2">
          <b-form-input v-model="form.nama_materi"></b-form-input>
        </b-form-group>
        <b-form-group label="Deskripsi" class="mb-2">
          <b-form-textarea v-model="form.deskripsi"></b-form-textarea>
        </b-form-group>
        <b-form-group label="Lampiran Materi (opsional)" class="mb-2">
          <input type="file" @change="attachFile" />
          <div v-if="form.uploaded_file" class="mt-2">
            <strong>
              Lampiran terupload:
              <a target="_blank" :href="form.uploaded_file">{{
                getFileName(form.uploaded_file)
              }}</a>
            </strong>
          </div>
        </b-form-group>
      </form>

      <template #modal-footer>
        <button class="btn btn-primary" @click.prevent="submit">Submit</button>
      </template>
    </b-modal>
    <!-- / -->
  </b-overlay>
</template>
<script>
import {
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BCard,
  BTable,
} from "bootstrap-vue";
export default {
  components: {
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCard,
    BTable,
  },
  data: () => ({
    form: {
      nama_materi: null,
      deskripsi: null,
      file: null,
      uploaded_file: null,
    },
    id: null,
    materis: [],
    loading: false,
    perPage: 10,
    pageOptions: [10, 30, 50],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    search: null,
    fields: [
      { key: "index", label: "No" },
      { key: "nama_materi", label: "Judul Materi", sortable: true },
      { key: "deskripsi", label: "Deskripsi", sortable: true },
      { key: "action", label: "#" },
    ],
    selected: [],
    total: [],
    record: [],
    optFilter: [
      { id: "name", value: "Nama" },
      // { id: "nip", value: "NIP" },
    ],
  }),
  computed: {
    isValidForm() {
      if (!this.form.nama_materi || !this.form.deskripsi) {
        return false;
      }

      return true;
    },
  },
  methods: {
    async hapus(item) {
      const conf = confirm("Anda yakin?");
      if (conf) {
        const { id } = item;

        const payload = {
          id,
          fungsi: 2,
        };

        try {
          this.loading = true;
          await this.$store.dispatch("materi/store", [payload]);
          await this.getMateri();
          this.loading = false;
        } catch (e) {
          this.loading = false;
          this.displayError(e);
          return false;
        }
      }
    },
    viewDetail(item) {
      const { nama_materi, deskripsi, file_materi } = item;
      this.form.nama_materi = nama_materi;
      this.form.deskripsi = deskripsi;
      if (file_materi && file_materi.length > 0) {
        const urlPath = `${process.env.VUE_APP_API_FILE}/${file_materi[0].file}`;
        this.form.uploaded_file = urlPath;
      }
      this.$bvModal.show("modal-detail");
    },
    getFileName(path) {
      const array = path.split("/");
      return array[array.length - 1];
    },
    async edit(item) {
      const { id, nama_materi, deskripsi, file_materi } = item;

      this.id = id;
      this.form.nama_materi = nama_materi;
      this.form.deskripsi = deskripsi;
      if (file_materi && file_materi.length > 0) {
        const urlPath = `${process.env.VUE_APP_API_FILE}/${file_materi[0].file}`;
        this.form.uploaded_file = urlPath;
      }

      this.$bvModal.show("modal-materi");
    },
    async submit() {
      if (!this.isValidForm) {
        this.displayError({
          message: "Harap lengkapi judul dan deskripsi materi",
        });
        return false;
      }

      const { nama_materi, deskripsi, file } = this.form;
      const payload = {
        nama_materi,
        deskripsi,
      };

      if (this.id) {
        payload.id = this.id;
      }

      try {
        this.loading = true;
        this.$bvModal.hide("modal-materi");

        const materi = await this.$store.dispatch("materi/store", [payload]);
        if (file) {
          const payloadFile = new FormData();
          payloadFile.append("materi_id", materi.id);
          payloadFile.append("file", file);

          await this.$store.dispatch("materi/uploadFile", payloadFile);
          this.loading = false;
          this.displaySuccess({
            message: "Materi berhasil diperbaharui",
          });
          this.getMateri();
        } else {
          this.loading = false;
          this.displaySuccess({
            message: "Materi berhasil diperbaharui",
          });
          this.getMateri();
        }
      } catch (e) {
        this.$bvModal.show("modal-materi");
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    attachFile(e) {
      this.form.file = e.target.files[0];
    },
    resetForm() {
      this.form.nama_materi = null;
      this.form.deskripsi = null;
      this.form.file = null;
      this.form.uploaded_file = null;
      this.id = null;
    },
    openModal() {
      this.resetForm();
      this.$bvModal.show("modal-materi");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getMateri() {
      const params = {};
      try {
        this.loading = true;
        const response = await this.$store.dispatch("materi/getData", params);
        this.materis = response?.data ? response.data : [];
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
  },
  created() {
    this.getMateri();
  },
};
</script>
